import * as React from 'react'
import { MouseoverTooltip } from '../components/Tooltip'
//定义接口属性
export interface Route {
  name: React.ReactNode
  icon: React.ReactNode
  path: string
}
const routes: Route[] = [
  {
    name: (
      <>
        <div style={{ position: 'relative' }}>
          NFT挖矿 <span style={{ top: '-10px', fontSize: '30px', position: 'absolute', right: '-40px' }}>🚀</span>
        </div>
      </>
    ),
    path: 'https://lp-nft.2dao.io/',
    icon: <></>
  },
  {
    name: <MouseoverTooltip text="敬请期待">NFT投票</MouseoverTooltip>,
    path: '',
    icon: <></>
  },
  {
    name: <MouseoverTooltip text="敬请期待">IDO</MouseoverTooltip>,
    path: '',
    icon: <></>
  }
]
export default routes
